<!--
 * @Author: your name
 * @Date: 2020-12-21 20:26:41
 * @LastEditTime: 2021-05-31 15:18:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\wxpay\index.vue
-->
<template>
  <div></div>
</template>
<script>
import base from "../../../util/base"
export default {
  async mounted() {
    await this.$toast.loading("请稍后");
    await wxparameter();
    await wxpay();
  },
  methods: {
    /**
     *
     * WIDtotal_amount：金额
     * order_type：订单类型 1.充值、2.购买。3、购买套餐、4.活动购买、5.购买套餐包、6.请求报告、7.批量购买、8.自查购买、9.退款
     * id：报告id
     * code：微信公众号授权code（支付需要使用）
     * panment：支付方式（支付方式 1.余额、2.微信、3.支付宝）
     * WIDsubject:订单名称
     * WIDbody：描述
     */
    wxparameter(
      WIDtotal_amount,
      order_type,
      panment = "2",
      WIDsubject = "购买套餐",
      WIDbody = "描述"
    ) {
      let uppayobj = {
        reqType: "cost",
        WIDsubject: WIDsubject,
        // WIDtotal_amount: this.ispay == true ? this.money : this.taomoney,
        WIDsubject: WIDsubject,
        WIDbody: WIDbody,
        panment: panment,
        //order_type: this.ip == "1" ? "8" : "1", //	订单类型 1.充值、2.购买。3、购买套餐、4.活动购买、5.购买套餐包、6.请求报告、7.批量购买、8.自查购买、9.退款
        order_type: order_type,
        id: this.$route.query.code,
        code: this.$route.query.code,
      };
      this.$http
        .post("/firm/v1/Login/wechatPhonePay", uppayobj)
        .then((res) => {
          // this.bbb = JSON.parse(res.data)

          this.a = {
            appId: JSON.parse(res.data).data.appId,
            timeStamp: JSON.parse(res.data).data.timeStamp,
            nonceStr: JSON.parse(res.data).data.nonceStr,
            package: JSON.parse(res.data).data.package,
            signType: "MD5",
            paySign: JSON.parse(res.data).data.paySign,
          };

          
        })
        .catch((res) => {
          // 防止重复点击
        });
    },
  },
  wxpay() {
    WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: this.a.appId,
        nonceStr: this.a.nonceStr,
        package: this.a.package,
        paySign: this.a.paySign,
        signType: this.a.signType,
        timeStamp: this.a.timeStamp,
      },
      function (res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          if (ispay == true) {
            alert("恭喜您，充值成功!更新可能会有5～10秒延迟!");
            window.location.href = base.url+"/recharge";
          } else {
            alert("恭喜您，支付成功!更新可能会有5～10秒延迟!");
            window.location.href =
              base.url+"/authorizationsuccess";
          }
        } else {
          window.history.go(-2);
        }
      }
    );
  },
};
</script>
<style scoped>
</style>